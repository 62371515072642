import * as React from 'react'
import PhoneInput from 'react-phone-number-input'
import { TFunctionResult, t } from 'i18next'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  FormControl, FormGroup,
  Grid,
  OutlinedInput,
  Popover,
  PopoverOrigin, Select, SelectChangeEvent,
  Stack, TextField, Tooltip,
  Typography, useTheme,
} from '@mui/material'
import MuiMenuItem from '@mui/material/MenuItem'
import { useDispatch } from '../../store'
import { showSnackbar } from '../../store/common/actionCreator'
import sprite_ from '../../sass/sprite.module.sass'
import { usePopover } from '../../hook/usePopover'
import { CombinedId, DeepWeb, ILookup, IPGeoNumber, Source, Type } from '../../store/searcher/reducer'
import { useCardStyles } from '../../style/card'
import { ErrorsState } from '../../function/validation'
import { styled } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/Delete'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import PostAddIcon from '@mui/icons-material/PostAdd'
import { MutableRefObject, useRef } from 'react'
import { getTitle } from '../../function/string'
import { PopupTextUpload } from '../../container/searcher/popups/PopupTextUpload'
import { countries, CountryType } from './Countries'
import { PopupPhoneListUpload } from '../../container/searcher/popups/PopupPhoneListUpload'
import NameByCountry from './inline-options/NameByCountry'


const minWidthFor2items = 900
const minWidthFor3items = 1536

interface LookupTitleProps {
  name: TFunctionResult
  price: number
}

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}))

const countWordsWorkflowTitle = (text : any) : number => {
  let length : number
  const arr = text.split(" ")
  if (arr.length >= 0 && arr.length < 4) {
    length = 18
  } else {
    if (arr.length >= 4 && arr.length <11) {
      length = 15
    } else { 
      length = 10
    }
  }
  return length
}

const countWordsWorkflowSubTitle = (text : any) : number => {
  let length : number
  const arr = text.split(" ")
  if (arr.length >= 0 && arr.length < 4) {
    length = 16
  } else {
    if (arr.length >= 4 && arr.length <11) {
      length = 13
    } else { 
      length = 10
    }
  }
  return length
}

function LookupTitle({ name, price }: LookupTitleProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={2} lineHeight="21px">
      <Typography variant="semiBold" fontSize={countWordsWorkflowTitle(name)} style={{flexWrap: 'wrap-reverse'}}>
        {name}
      </Typography>
      <Typography variant="bold" color="primary" fontSize={25}>
        €{price}
      </Typography>
    </Stack>
  )
}

interface Props {
  index: number
  data: ILookup
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    data: ILookup,
    input: string,
    inputType: Source | undefined,
    country: string | undefined,
    inputIP: string | undefined
  ) => ErrorsState | undefined
}

export function LookupInline({ index, data, onSubmit }: Props) {
  const dispatch = useDispatch()
  const cardClasses = useCardStyles()
  const theme = useTheme()
  const uploadRef = useRef() as MutableRefObject<HTMLInputElement>

  const [input, setInput] = React.useState('')
  const [inputIp, setInputIp] = React.useState('')
  //const [inputEmail, setInputEmail] = React.useState('')
  const [inputType, setInputType] = React.useState<DeepWeb>(data?.sources?.[0] as DeepWeb)
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const inputRef = useRef<HTMLInputElement>()

  const initCountry: CountryType = {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
  }
  
  const [country, setCountry] = React.useState<CountryType>(initCountry)

  React.useEffect(() => {
    setInput('')
    setBase64(undefined)
    setInputType(data?.sources?.[0] as DeepWeb)
  }, [data])
  React.useEffect(() => {
    setErrors(null)
  }, [input])
  React.useEffect(() => {
    if (errors) {
      dispatch(showSnackbar('error', String(errors?.input)))
    }
  }, [errors, dispatch])

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [popoverOpen, setPopoverOpen] = React.useState(false)

  const isOnRightSideOnPage = React.useMemo(() => {
    if (window.innerWidth < minWidthFor2items) {
      return true
    } else if (window.innerWidth < minWidthFor3items) {
      return (index + 1) % 2 === 0
    } else {
      return (index + 1) % 3 === 0
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, window.innerWidth])

  const { popoverClasses, popoverOptions } = usePopover(isOnRightSideOnPage)

  const tooltip = React.useMemo(() => {
    return data.type === 'phone'
      ? t('package.tooltip.app')
      : t('package.tooltip.platforms')
  }, [data.type])

  const handlePopoverEnter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setPopoverOpen(true)
  }

  const handlePopoverLeave = () => {
    setPopoverOpen(false)
  }

  const handleTypeChange = (event: SelectChangeEvent<DeepWeb>) => {
    const { target: { value } } = event
    setInputType(value as DeepWeb)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (inputType as IPGeoNumber === 'email_domain_validator') {
      setInputIp('127.0.0.1')
    }

    const error = onSubmit(e, data, input, inputType, country.label, inputIp)

    if (error) {
      setErrors(error)
    } else {
      setErrors(null)
      if (data.type !== 'face_search' && data.type !== 'irbis_eye' && data.type !== 'photo_searcher') {
        setInput('')
        setInputIp('')
      } else {
        handleDelete()
      }
    }
  }

  const returnPlaceHolderValue = (type : string | null | undefined)  => {
    switch (type) {
      case 'email' :
        return 'Enter email'
      case 'facebookid':
        return 'Facebook ID'
      case 'fullname' : 
        return 'Name Surname'
      case 'linkedinid':
        return 'LinkedIn ID'
      case 'password' :
        return 'password'
      case 'phone' :
        return '+123456789'
      case 'telegramid':
        return 'Telegram ID'
      case 'vkid':
        return 'Vk ID (vkontakte)'
      case 'twitterid':
        return 'Twitter ID'
      case 'instagramid':
        return 'Instagram ID'
      case 'instanavigation_person':
        return 'Instagram ID:'
      case 'geolocation' : 
        return "138.162.214.215"
      case 'web_collection_screenshot' :
        return "https://irbis.espysys.com"
      case 'web_collection_scrape' :
        return "https://irbis.espysys.com"
      case 'kyc_iban' :
        return "BE01234567890123"
      case 'kyc_company_enrich' :
        return "irbis.espysys.com"
      case 'kyc_vat_validate': 
        return 'AB012345678901'
      case 'country_fullname' : 
        return 'Full Name'
      case 'phone_list' :
        return '+123456789; +123456789; ... +123456789;'
      case 'summary' :
        return 'FacebookID'
      case 'psych' :
          return 'FacebookID'
      default:
          return ""
    }
  }

  const [base64, setBase64] = React.useState(undefined)
  const [textOpen, setTextOpen] = React.useState(false)
  const [phoneListOpen, setPhoneListOpen] = React.useState(false)
  const cropperSrc = React.useMemo(() => {
    return base64
  }, [base64])
  
  const handleUpload = ({ target }: any) => {
    const fileTypes = ['jpg', 'jpeg', 'png']
    const file = target.files[0]
    const extension = file.name.split('.').pop().toLowerCase()
    const isSuccess = fileTypes.indexOf(extension) > -1
    const isOverWeight = file.size > 1024 * 1024 * 4
    if (isOverWeight) {
      dispatch(showSnackbar('error', 'Photo size should be less than 4 Mb.'))
    } else {
      if (isSuccess) {
        const fileReader = new FileReader()

        fileReader.readAsDataURL(file)

        fileReader.onload = (e: any) => {
          if (e.target) {
            setBase64(e.target.result)
            setInput(e.target.result)
          }
        }
      } else {
        dispatch(showSnackbar('error', t('message.error.profilePicture')))
      }
    }
  }

  const handleDelete = () => {
    if (uploadRef && uploadRef.current) {
      uploadRef.current.value = ''
    }
    setBase64(undefined)
    setInput('')
  }

  function getPsychoProfileTooltipOrTheOthers(source: string){
    if (source === 'Psych'){
      return 'Psychological Portrait'
    }
    else {
      return source
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Stack
          direction="column"
          //justifyContent="space-between"
          //alignItems="flex-start"
          sx={{paddingTop: '8px', width: '100%'}}
        >
          <Stack
            direction={'row'}
            gap={3}
            sx={{
              margin: 0,
              width: '100%',
              [theme.breakpoints.only("xs")]: {
                flexDirection: 'column',
              },
            }}
          >
            <Box sx={{ flexShrink: 1, width: '100%'}}>
              {
                (() => {
                  if ( data.type === 'kyc' || data.type === 'web_collection' || data.type === 'ip_geo' || data.type === 'combined_id' || data.type === 'psycho_profile') {
                    return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                    <FormGroup row style={{flexWrap: 'nowrap'}}>
                      <Select
                        value={inputType}
                        onChange={handleTypeChange}
                        input={
                          <OutlinedInput
                            defaultValue={data.sources?.[0]}
                            sx={{backgroundColor: '#F5F8FF'}}
                          />
                        }
                      >

                        {data.sources.map((source) => (
                          <MenuItem key={source} value={source}>
                            <Tooltip title={getTitle(source)} placement="top">
                              <Box
                                sx={{
                                  marginBottom: '5px !important',
                                  [theme.breakpoints.only("xs")]: {
                                    marginLeft: '2px!important',
                                    marginRight: '1px',
                                  },
                                }}
                                key={getPsychoProfileTooltipOrTheOthers(source)}
                                className={sprite_['social__small_' + source]}
                              />
                            </Tooltip>
                          </MenuItem>
                        ))}
                      </Select>
                          <OutlinedInput
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder={returnPlaceHolderValue(inputType)}
                            style={ {marginLeft: 0, width: '100%' } }
                          />
                    </FormGroup>
                  </div>
                  } else {
                  switch(data.type) {
                    case 'phone': return <FormControl fullWidth>
                      <PhoneInput
                        className={
                          errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                        }
                        international
                        value={input}
                        placeholder={data.input}
                        onChange={(val) => setInput(val as string)}
                      />
                    </FormControl>
                    case 'deepweb': return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>
                        <Select
                          value={inputType}
                          onChange={handleTypeChange}
                          input={
                            <OutlinedInput
                              defaultValue={data.sources?.[0]}
                              sx={{backgroundColor: '#F5F8FF'}}
                            />
                          }
                          className={
                            errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                          }
                        >

                          {data.sources.map((source) => (
                            <MenuItem key={source} value={source}>
                              <Tooltip title={getTitle(source)} placement="top">
                                <Box
                                  sx={{
                                    marginBottom: '5px !important',
                                    [theme.breakpoints.only("xs")]: {
                                      marginLeft: '2px!important',
                                      marginRight: '1px',
                                    },
                                  }}
                                  key={source}
                                  className={sprite_['social__small_' + source]}
                                />
                              </Tooltip>
                            </MenuItem>
                          ))}
                        </Select>

                        {( inputType === 'phone' ?
                            <PhoneInput
                              className={
                                errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                              }
                              international
                              value={input}
                              placeholder={data.input}
                              onChange={(val) => setInput(val as string)}
                              style={ {marginLeft: 5 } }
                            />
                            :
                            <OutlinedInput
                              value={input}
                              error={!!errors?.input}
                              onChange={(e) => setInput(e.target.value)}
                              placeholder={returnPlaceHolderValue(inputType)}
                              style={ {marginLeft: 0, width: '100%' } }
                              className={
                                errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                              }
                            />
                        )}
                      </FormGroup>
                    </div>
                    case 'combined_email': return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>

                        <OutlinedInput
                          value={input}
                          error={!!errors?.input}
                          onChange={(e) => setInput(e.target.value)}
                          placeholder={returnPlaceHolderValue(inputType)}
                          style={ {marginLeft: 5, width: '100%' } }
                          className={
                            errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                          }
                        />

                      </FormGroup>
                    </div>
                    case 'combined_phone': return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>
                        <PhoneInput
                          className={
                            errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                          }
                          international
                          value={input}
                          placeholder={data.input}
                          onChange={(val) => setInput(val as string)}
                          style={ {marginLeft: 5, width: '100%' } }
                        />
                      </FormGroup>
                    </div>
                    case 'phone_list': return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                      <FormGroup row style={{ flexWrap: 'nowrap' }}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                          <Grid item sx={{ width: '100%' }}>
                            {
                              <a
                                onClick={() => setPhoneListOpen(true)}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '43px',
                                  cursor: 'pointer',
                                  border: '1px solid #2E97D5',
                                  //borderRadius: '4px',
                                  background: 'none',
                                }}
                              >
                                <PostAddIcon color='primary' sx={{ marginRight: '10px' }} />
                                {
                                  input
                                    ? <>{input.substring(0, 20)}...</>
                                    : 'Enter phone number list'
                                }
                              </a>
                            }
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </div>
                    case 'face_search': return <>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                          <Grid item sx={{width: '100%'}}>
                            {
                              cropperSrc && <img src={cropperSrc} width={100} height={100} style={{objectFit: 'cover'}} />
                            }

                            {
                              !cropperSrc && <label
                                htmlFor={`uploadImage${data.id}`}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '43px',
                                  cursor: 'pointer',
                                  border: '1px solid #2E97D5',
                                  //borderRadius: '4px',
                                  background: 'none',
                                }}
                              >
                                <AddPhotoAlternateIcon color='primary' sx={{marginRight: '10px'}} />
                                Upload image
                              </label>
                            }
                            {
                              cropperSrc && <label onClick={handleDelete} htmlFor={`deleteImage${data.id}`} style={{cursor: 'pointer'}}>
                                <DeleteIcon color='primary'/>
                              </label>
                            }
                          </Grid>
                        </Grid>
                        <input
                          ref={uploadRef}
                          type="file"
                          accept="image/*"
                          id={`uploadImage${data.id}`}
                          style={{ display: 'none' }}
                          onChange={handleUpload}
                        />
                      </FormGroup>
                    </>
                    case 'photo_searcher': return <>
                    <FormGroup row style={{flexWrap: 'nowrap'}}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                        <Grid item sx={{width: '100%'}}>
                          {
                            cropperSrc && <img src={cropperSrc} width={100} height={100} style={{objectFit: 'cover'}} />
                          }

                          {
                            !cropperSrc && <label
                              htmlFor={`uploadImage${data.id}`}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '43px',
                                cursor: 'pointer',
                                border: '1px solid #2E97D5',
                                background: 'none',
                              }}
                            >
                              <AddPhotoAlternateIcon color='primary' sx={{marginRight: '10px'}} />
                              Upload image
                            </label>
                          }
                          {
                            cropperSrc && <label onClick={handleDelete} htmlFor={`deleteImage${data.id}`} style={{cursor: 'pointer'}}>
                              <DeleteIcon color='primary'/>
                            </label>
                          }
                        </Grid>
                      </Grid>
                      <input
                        ref={uploadRef}
                        type="file"
                        accept="image/*"
                        id={`uploadImage${data.id}`}
                        style={{ display: 'none' }}
                        onChange={handleUpload}
                      />
                    </FormGroup>
                    </>
                    case 'sentiment_analysys': return <>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                          <Grid item sx={{width: '100%'}}>
                            {
                              <a
                                onClick={() => setTextOpen(true)}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '43px',
                                  cursor: 'pointer',
                                  border: '1px solid #2E97D5',
                                  //borderRadius: '4px',
                                  background: 'none',
                                }}
                              >
                                <PostAddIcon color='primary' sx={{marginRight: '10px'}} />
                                {
                                  input
                                    ? <>{input.substring(0, 20)}...</>
                                    : 'Enter text'
                                }
                              </a>
                            }
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </>
                    case 'irbis_eye': return <>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                          <Grid item sx={{width: '100%'}}>
                            {
                              cropperSrc && <img src={cropperSrc} width={100} height={100} style={{objectFit: 'cover'}} />
                            }

                            {
                              !cropperSrc && <label
                                htmlFor={`uploadImage${data.id}`}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  width: '100%',
                                  height: '43px',
                                  cursor: 'pointer',
                                  border: '1px solid gray',
                                  borderRadius: '4px',
                                  background: 'none',
                                }}
                              >
                                <AddPhotoAlternateIcon color='primary' sx={{marginRight: '10px'}} />
                                Upload image
                              </label>
                            }
                            {
                              cropperSrc && <label onClick={handleDelete} htmlFor={`deleteImage${data.id}`} style={{cursor: 'pointer'}}>
                                <DeleteIcon color='primary'/>
                              </label>
                            }
                          </Grid>
                        </Grid>
                        <input
                          ref={uploadRef}
                          type="file"
                          accept="image/*"
                          id={`uploadImage${data.id}`}
                          style={{ display: 'none' }}
                          onChange={handleUpload}
                        />
                      </FormGroup>
                    </>
                    case 'ipgeo_number': 
                    return <div className={'MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3'}>
                      <FormGroup row style={{flexWrap: 'nowrap'}}>
                        <Select
                          value={inputType}
                          onChange={handleTypeChange}
                          input={
                            <OutlinedInput
                              defaultValue={data.sources?.[0]}
                              sx={{backgroundColor: '#F5F8FF'}}
                            />
                          }
                          className={
                            errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                          }
                        >

                          {data.sources.map((source) => (
                            <MenuItem key={source} value={source}>
                              <Tooltip title={getTitle(source)} placement="top">
                                <Box
                                  sx={{
                                    marginBottom: '5px !important',
                                    [theme.breakpoints.only("xs")]: {
                                      marginLeft: '2px!important',
                                      marginRight: '1px',
                                    },
                                  }}
                                  key={source}
                                  className={sprite_['social__small_' + source]}
                                />
                              </Tooltip>
                            </MenuItem>
                          ))}
                        </Select>

                        {( inputType as IPGeoNumber === 'geolocation_number' ?
                            <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <PhoneInput
                                style={{marginLeft: 10 }}
                                className={
                                  errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                                }
                                international
                                value={input}
                                placeholder={ JSON.parse(data.input).phone ? JSON.parse(data.input).phone : ""}
                                onChange={(val) => setInput(val as string)}
                              />
                            </Grid> 
                            <Grid item xs={6}>
                              <OutlinedInput
                                value={inputIp}
                                error={!!errors?.input}
                                onChange={(e) => setInputIp(e.target.value)}
                                placeholder={JSON.parse(data.input).ip ? JSON.parse(data.input).ip : ""}
                                style={{ marginLeft: 0, width: '100%' }}
                                className={
                                  errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                                }
                              />
                            </Grid>
                          </Grid>
                            :
                            <OutlinedInput
                              value={input}
                              error={!!errors?.input}
                              onChange={(e) => setInput(e.target.value)}
                              placeholder={'email@email.com'}
                              style={ {marginLeft: 0, width: '100%' } }
                              className={
                                errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                              }
                            />
                        )}
                      </FormGroup>
                    </div>
                    case 'name_by_country': return <NameByCountry 
                      input={input} 
                      country={country} 
                      setInput={setInput} 
                      setCountry={setCountry}
                      placeholder={returnPlaceHolderValue(inputType)}/>

                    default: return <FormControl fullWidth>
                      <OutlinedInput
                        className={
                          errors?.input ? ' form__phoneinline_error' : 'form__phoneinline'
                        }
                        value={input}
                        error={!!errors?.input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder={data.input}
                        label={null}
                      />
                    </FormControl>
                  }
                }
                })()
              }
              <Stack direction={'row'} sx={{paddingTop: '10px'}} justifyContent={'center'} alignItems={'center'}>
                <Typography
                  fontSize={12}
                  color={'#ACAFB6'}
                >{data.subTitle}</Typography>
                {
                  ( (data.type !== 'combined_phone' && data.type !== 'real_phone' 
                    && data.type !== 'phone' && data.type !== 'verify_name' && data.type !== 'phone_list') &&
                  <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                  flexWrap="wrap"
                  ml={1}
                  sx={{
                    [theme.breakpoints.only("xs")]: {
                      justifyContent: 'flex-start',
                      margin: '10px 0',
                      display: 'none',
                    },
                  }}
                >
                  {data.sources.map((el) => (
                    <Box
                      sx={{
                        marginBottom: '5px !important',
                        [theme.breakpoints.only("xs")]: {
                          marginLeft: '2px!important',
                          marginRight: '1px',
                        },
                      }}
                      key={el}
                      className={(el === 'fb_api' || el === 'instagram_api' || el === 'x_api' 
                        || el === 'linkedin_api' || el === 'vk_api' || el === 'ok_api') 
                      ? sprite_['social__small_' + el.replace("_api","")] : sprite_['social__small_' + el]}
                    />
                  ))}
                </Stack>
                  )
                }
              </Stack>
            </Box>
            <Box sx={{flexShrink: 0}}>
              <Button type="submit" variant="contained" fullWidth sx={{
                [theme.breakpoints.only("xs")]: {
                  width: '100%',
                },
              }}>
                {data.button}
              </Button>
            </Box>
          </Stack>
      </Stack>
      </form>

      <Popover
        open={popoverOpen}
        className={popoverClasses.root}
        classes={{
          paper: popoverClasses.paper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={popoverOptions.anchorOrigin as PopoverOrigin}
        transformOrigin={popoverOptions.transformOrigin as PopoverOrigin}
        PaperProps={{
          onMouseEnter: () => setPopoverOpen(true),
          onMouseLeave: handlePopoverLeave,
        }}
        elevation={0}
      >
        {tooltip}
      </Popover>
      {textOpen && (
        <PopupTextUpload
          input={input}
          onClose={() => setTextOpen(false)}
          onSetInput={(text) => setInput(text)}
        />
      )}
      {phoneListOpen && (
        <PopupPhoneListUpload
          input={input}
          onClose={() => setPhoneListOpen(false)}
          onSetInput={(text) => setInput(text)}
        />
      )}
    </>
  )
}