import { isArray, isNumber } from 'lodash'
import moment from 'moment'
import { SearchResult } from '../store/searcher/reducer'
import { email } from './validation'

export function getNameVerificationCriteria(nameVerificationCriteria: any, counter: number) {
  const criteria = JSON.parse(nameVerificationCriteria)
  let resultString: string = nameVerificationCriteria.substr(0, counter) + "..."
  if (criteria.phoneNumber && criteria.name) {
    resultString = `${criteria.phoneNumber}/${criteria.name}`
    if (resultString.length > counter) {
      return resultString.substr(0, counter) + "..."
    }
  }
  return resultString
}

export function truncate2(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + '&hellip;' : str
}

export function truncate(str: string, n: number, useWordBoundary = false) {
  if (str.length <= n) {
    return str
  }
  const subString = str.substr(0, n - 1) // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '...'
  ) // &hellip;
}

export function isTextLimitPassed(text: string, limit: number) {
  return text.replaceAll(/\.|"/g, '').length > limit
}

export function isRTL(text: string) {
  const rtlChars = '\u0591-\u07FF\u200F\u202B\u202E\uFB1D-\uFDFD\uFE70-\uFEFC',
    rtlDirCheck = new RegExp('^[^' + rtlChars + ']*?[' + rtlChars + ']')

  return rtlDirCheck.test(text)
}

export const getFontFamily = (value: string, secondFont: string) => {
  return isRTL(value) ? 'sunExta' : secondFont
}

export const parseText = (text: string): string => {
  const textArr = text
    .replace(/\s+(?=[^[\]]*\])/g, '-')
    .split(' ')
    .map((word) => {
      if (
        word.startsWith('[id') ||
        word.startsWith('[club') ||
        word.startsWith('[https')
      ) {
        let [link, name] = word.split('|')
        link = link.replace(/-/g, '').replace('[', '')
        name = name.replace(/-/g, ' ').replace(']', '')
        return name
      }
      return word
    })
  return textArr.join(' ')
}

export const getTitle = (text: string) => {
  if (typeof text === 'number') {
    text = String(text)
  }
  if (typeof text !== 'string') {
    return ''
  }
  if (text.toLowerCase() === 'psych') {
    return 'Psychological portrait'
  }
  let finalResult = text
  if (text && typeof text === 'string') {
    if (text.indexOf("_") > 0) {
      finalResult = text
        .split("_")
        .map(
          (name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        )
        .join(" ")
    } else {
      const result = text.replace(/([A-Z])/g, " $1")
      finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    }
  }
  
  switch (finalResult.toLowerCase()) {
    case 'callapp' : 
      return "Cosmos"
    case 'eyecon' : 
      return 'Zephyr'
    default :
      return finalResult
  }

  //return finalResult
}

export const normalizeSocialNetwork = (sn: string) => {
  switch (sn) {
    case 'facebook.com':
      return 'fb'
    case 'vk.com':
      return 'vk'
    default:
      return sn
  }
}

export const getLinkToProfile = (snName: string, profileId: string) => {
  profileId = profileId + ''
  switch (snName?.toLowerCase()) {
    case "facebook_id":
    case "facebookid":
    case "facebook":
      {
        return `https://www.facebook.com/profile.php?id=${profileId}`
      }
    case "telegram_id": {
      return `https://telegram.me/${profileId}`
    }
    case "vk_id":
    case "vkid": {
      return `https://vk.com/id${profileId.replace('id', '')}`
    }
    case "linkedinusername":
    case "linkedin_id":
    case "linkedin":
      {
        return `https://www.linkedin.com/in/${profileId}`
      }
    case "twitter_username":
    case "twitterusername":
    case "twitterscreenname":
    case "twitter_id":
    case "twitter":
      {
        return `https://twitter.com/${profileId}`
      }
    case "instagramusername":
    case "instagram":
      {
        return `https://www.instagram.com/${profileId}`
      }
    /*case "googleplus": {
      return `https://plus.google.com/${profileId}`
    }*/
    default: {
      return ""
    }
  }
}

export function isHttpUrl(string: string): boolean {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

export function getUrl(url: string): string {
  if (url) {
    if (url.indexOf("http") === 0) {
      return url
    }
    if (url.indexOf("//") === 0) {
      return url
    }
    return "https://" + url
  }
  return url
}

function extractSocialMediaInfo(url: string) {
  // Regular expression to match social media URL patterns
  const linkedInRegex = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/(?:in\/([^\/?]+)\/?|company\/([^\/?]+)\/?)/
  const instagramRegex = /(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:p\/([^\/]+)\/?|(?:[^\/]+\/)?([^\/?]+))/
  const flickrRegex = /(?:https?:\/\/)?(?:www\.)?flickr\.com\/photos\/([^\/]+)\/?/
  const tiktokRegex = /(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@([^\/?]+)\/?/
  const facebookRegex = /(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:[^\/?]+\/)?(?:posts\/([^\/?]+)\/|(?:[^\/?]+\/)?([^\/?]+)\/?)/
  const twitterRegex = /(?:https?:\/\/)?(?:www\.)?twitter\.com\/([^\/?]+)\/?/
  const githubRegex = /(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/?]+)\/?/


  // Extract matches from the LinkedIn URL
  const linkedInMatches = url.match(linkedInRegex)

  if (linkedInMatches) {
    const userId = linkedInMatches[1]
    const companyId = linkedInMatches[2]

    if (userId) {
      return {
        network: 'linkedin',
        user_id: userId,
      }
    } else if (companyId) {
      return {
        network: 'linkedin',
        user_id: companyId,
      }
    }
  }

  // Extract matches from the Instagram URL
  const instagramMatches = url.match(instagramRegex)

  if (instagramMatches) {
    const postId = instagramMatches[1]
    const instaUserId = instagramMatches[2]

    if (postId) {
      return {
        network: 'instagram',
        user_id: postId,
      }
    } else if (instaUserId) {
      return {
        network: 'instagram',
        user_id: instaUserId,
      }
    }
  }

  // Extract matches from the Flickr URL
  const flickrMatches = url.match(flickrRegex)

  if (flickrMatches) {
    const flickrUserId = flickrMatches[1]

    if (flickrUserId) {
      return {
        network: 'flickr',
        user_id: flickrUserId,
      }
    }
  }

  const tiktokMatches = url.match(tiktokRegex)

  if (tiktokMatches) {
    const tiktokUserId = tiktokMatches[1]

    if (tiktokUserId) {
      return { network: 'tiktok', user_id: tiktokUserId }
    }
  }

  const facebookMatches = url.match(facebookRegex)

  if (facebookMatches) {
    const postId = facebookMatches[1]
    const facebookUserId = facebookMatches[2]

    if (postId) {
      return { network: 'fb', user_id: postId }
    } else if (facebookUserId) {
      return { network: 'fb', user_id: facebookUserId }
    }
  }

  const twitterMatches = url.match(twitterRegex)

  if (twitterMatches) {
    const twitterUserId = twitterMatches[1]

    if (twitterUserId) {
      return { network: 'twitter', user_id: twitterUserId }
    }
  }

  const githubMatches = url.match(githubRegex)

  if (githubMatches) {
    const githubUserId = githubMatches[1]

    if (githubUserId) {
      return { network: 'github', user_id: githubUserId }
    }
  }

  // URL doesn't match the expected patterns
  return {
    network: 'web',
    user_id: 'Link',
  }
}

export function getImageCardInfo(imgObj: any): any {
  let item = { ...imgObj }
  if (item.base64 && !item.face) {
    item.face = item.base64
    delete item.base64
    if (!item.source) {
      item.source = item.face
    }
  }
  //face
  if (!item.face && item.thumbnail) {
    item.face = item.thumbnail
    delete item.thumbnail
    if (!item.source) {
      item.source = item.face
    }
  }
  if (!item.face && item.s3Image) {
    item.face = item.s3Image
    if (!item.source) {
      item.source = item.face
    }
  }

  //photo id
  if (!item.photo_id && item.guid) {
    item.photo_id = item.guid
  }
  if (!item.photo_id && item.userid) {
    item.photo_id = item.userid
  }
  if (!item.photo_id && item.s3Image) {
    item.photo_id = item.s3Image
  }

  //title
  if (!item.first_name && item.title) {
    item.first_name = item.title
    delete item.title
  }
  if (!item.first_name && item.firstname) {
    item.first_name = item.firstname
    delete item.firstname
  }

  if (!item.network && item.details?.length > 0) {
    if (item.details[0]?.url && item.details[0]?.url?.indexOf('pp.userapi') > 0) {
      item.network = 'vk'
      item.user_id = item.userid
      item.profile = getLinkToProfile('vkid', item.user_id)
    }
  }
  if (item.url) {
    const snInfo = extractSocialMediaInfo(item.url)
    if (snInfo) item = { ...item, ...snInfo }

    if (item.url?.indexOf('iafd.com') > 0) {
      item.network = 'iafd'
      item.user_id = item.url.split('/')?.[6]?.replace('.htm', '')
    }
    item.profile = item.url
  }
  if (!item.profile && item.source && isHttpUrl(item.source)) {
    const snInfo = extractSocialMediaInfo(item.source)
    if (snInfo) item = { ...item, ...snInfo }
    item.profile = item.source
  }
  if (!item.profile && item.link && isHttpUrl(item.link)) {
    item.profile = item.link
    item.network = 'web'
    item.user_id = item.source || 'Link'

    if (item.source_icon) {
      item.network_icon = item.source_icon
    }
  }

  if (item?.profileData && item?.profileData.image_url) {
    item.face = item?.profileData.image_url
    item.network = 'sniper_2'
    item.user_id = extractDomainFromString(item?.profileData.website_url)
    item.source = item?.profileData.website_url
    item.profile = item?.profileData.website_url
    item.photo_id = item?.profileData.website_url
  }

  return item
}

function extractDomainFromString(inputString: string): string {
  let result = 'no source link'
  const domainRegex = /(?:https?:\/\/)?(?:www\.)?([^\/]+)(?:\/|$)/i
  const match = inputString.match(domainRegex)
  if (match && match[1]) {

    match[1].length > 16 ?
      result = `${match[1].substring(0, 15)}...` :
      result = match[1]

  }
  return result
}

export function renderComplexItem(item: any, title: string, dateExceptions: string[] = []) {
  if (typeof item === 'string' && dateExceptions.includes(title)) {
    return moment(item).format("YYYY-DD-MM")
  }
  if (typeof item === 'string') {
    return item
  } else {
    return JSON.stringify(item).replace(/[\[\]}{"]/g, ' ')
  }
}

export function cleanJSON(row: SearchResult) {
  const el: any = JSON.parse(JSON.stringify(row))

  const deleteFirstLevel = ['id', 'isDeleted', 'isFree', 'isSuccess', 'percentage', 'requestId', 'requestIdFB', 'requestIdINSTA', 'requestIdLNK', 'requestIdOK',
    'requestIdPLUTOfacebookId', 'requestIdPLUTOfullName', 'requestIdPLUTOinstagramId', 'requestIdPLUTOlinkedinId', 'requestIdPLUTOtelegramId',
    'requestIdPLUTOtwitterId', 'requestIdPLUTOvkId', 'requestIdPhoto', 'requestIdTW', 'requestIdVK', 'usage', 'userId', 'createdTime']

  const plutoSources = ['email', 'phone', 'facebookid', 'telegramid', 'password', 'linkedinid', 'vkid', 'fullname', 'twitterid', 'instagramid']

  const sources: any = []

  const removeProperty = (property: string, obj: any) => {
    for (const prop in obj) {
      if (prop === property) {
        delete obj[prop]
      }
      else if (typeof obj[prop] === 'object') {
        removeProperty(property, obj[prop])
      }
    }
  }

  deleteFirstLevel.forEach((key: any) => {
    if (el[key] !== undefined) {
      delete el[key]
    }
  })

  if (el.sources !== undefined && Array.isArray(el.sources)) {
    el.sources.forEach((source: any) => {
      if (source.name !== undefined && source.percentage !== undefined) {
        delete source.percentage
        if (plutoSources.includes(source.name)) {
          source.name = 'pluto'
        }
        sources.push(source.name)

      }
    })
  }

  (el as any).data = []

  if (el.apiDataEntities !== undefined && Array.isArray(el.apiDataEntities)) {
    el.apiDataEntities.forEach((entity: any) => {
      if (entity.data !== undefined && entity.data !== null && entity.from != undefined) {
        let key = entity.from.toLowerCase()
        if (key === 'findclone') {
          key = 'irbis_eye'
        }
        const temp: any = {}
        if (Array.isArray(entity.data)) {
          temp[key] = { [key]: entity.data }
        } else {
          temp[key] = entity.data
        }

        if (temp[key] && temp[key].failed !== undefined) {
          delete temp[key].failed
        }
        if (temp[key] && temp[key].isLast !== undefined) {
          delete temp[key].isLast
        }
        if (temp[key] && temp[key].local_time !== undefined) {
          delete temp[key].local_time
        }
        if (temp[key] && temp[key].misconfigured !== undefined) {
          delete temp[key].misconfigured
        }
        if (temp[key] && temp[key].request !== undefined) {
          delete temp[key].request
        }

        if (temp[key] && temp[key].type !== undefined) {
          delete temp[key].type
        }
        if (temp[key] && temp[key].error !== undefined) {
          delete temp[key].error
        }

        // if (temp[key] && temp[key].query!== undefined) {
        //   delete temp[key].query
        // }

        if (temp[key] && temp[key].isMultiPhoneRequest !== undefined) {
          delete temp[key].isMultiPhoneRequest
        }

        if (temp[key] && temp[key].from !== undefined) {
          delete temp[key].from
        }

        try {
          if (temp[key].tc && temp[key].tc.contact && temp[key].tc.contact.id) {
            delete temp[key].tc.contact.id
          }
          //remove tc-contact-imId
          if (temp[key].tc && temp[key].tc.contact && temp[key].tc.contact.imId) {
            delete temp[key].tc.contact.imId
          }
          //remove tc-contact-cacheTtl
          if (temp[key].tc && temp[key].tc.contact && temp[key].tc.contact.cacheTtl) {
            delete temp[key].tc.contact.cacheTtl
          }
          //remove tc-contact-surveys
          if (temp[key].tc && temp[key].tc.contact && temp[key].tc.contact.surveys) {
            delete temp[key].tc.contact.surveys
          }
        } catch (e) {

        }

        el.data.push(temp[key])

        el.data.map((d: any) => { removeProperty("customrequestid", d) })
      }
    })
  }

  if (el.apiDataEntities) {
    delete el.apiDataEntities
  }

  return JSON.stringify(el)
}

function timeStampToDate(unix_timestamp: any) {
  if (isNumber(unix_timestamp)) {
    const date = new Date(unix_timestamp)
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    return `${day}.${month + 1}.${year}`
  } else {
    return ''
  }
}

function tamTamNames(tamtam: any) {
  const names: any = []
  if (tamtam && tamtam.name && tamtam.name !== '') {
    names.push(tamtam.name)
  }
  if (tamtam && tamtam.names && isArray(tamtam.names)) {
    tamtam.names.forEach((el: any) => {
      if (el.name && el.type && el.type === 2) {
        names.push(el.name)
      }
    })
  }
  return names !== [] ? names.toString() : ''
}

function getCallappEmails(callapp: any) {
  const callappEmails: any = []
  if (callapp && callapp.email && callapp.email !== '') {
    callappEmails.push(callappEmails.email)
  }
  if (callapp.emails && isArray(callapp.emails)) {
    callapp.emails.forEach((el: any) => {
      if (el.email && el.type && el.type === 4) {
        callappEmails.push(el.email)
      }
    })
  }
  return callappEmails !== [] ? callappEmails.toString() : ''
}

export function generateXLSXData(object: any) {
  const result: any = []
  const lsiWatsapp: any = []
  const lsiTelegram: any = []
  const lsiCallapp: any = []
  const lsiDrupe: any = []
  const lsiEyecon: any = []
  const lsiGetcontact: any = []
  const lsiHiya: any = []
  const lsiSkype: any = []
  const lsiTc: any = []
  
  const whatsapp: any = []
  let telegram: any = []
  let callapp: any = []
  let drupe: any = []
  let eyecon: any = []
  let getcontact: any = []
  let hiya: any = []
  let skype: any = []
  let tc: any = []

  if (object.data && isArray(object.data)) {
    object.data.forEach((el: any) => {

      if (el.whatsapp && el.whatsapp.map) { 
        const map = new Map(Object.entries(el.whatsapp.map))
        lsiWatsapp.push(Array.from(map, ([key, value]) => ({ key, value })))
      }
      
      if (el.telegram && el.telegram.map) { //checked 
        const map = new Map(Object.entries(el.telegram.map))
        telegram = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.callapp && el.callapp.map) { //(isMultiPhoneRequest: false) 
        const map = new Map(Object.entries(el.callapp.map))
        callapp = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.getcontact && el.getcontact.map) { //(isMultiPhoneRequest: false) 
        const map = new Map(Object.entries(el.getcontact.map))
        getcontact = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.drupe && el.drupe.map) { //(isMultiPhoneRequest: false) 
        const map = new Map(Object.entries(el.drupe.map))
        drupe = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.eyecon && el.eyecon.map) { //(isMultiPhoneRequest: false) 
        const map = new Map(Object.entries(el.eyecon.map))
        eyecon = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.hiya && el.hiya.map) { //(isMultiPhoneRequest: false) 
        const map = new Map(Object.entries(el.hiya.map))
        hiya = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.skype && el.skype.map) {
        const map = new Map(Object.entries(el.skype.map))
        skype = Array.from(map, ([key, value]) => ({ key, value }))
      }

      if (el.tc && el.tc.map) { //(isMultiPhoneRequest: false) 
        const map = new Map(Object.entries(el.tc.map))
        tc = Array.from(map, ([key, value]) => ({ key, value }))
      }


      if (el.telegram && !el.telegram.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "telegram",
          name: el.telegram.name ? el.telegram.name : "",
          nickname: el.telegram.nickname ? el.telegram.nickname : "",
          last_seen: el.telegram.last_time ? timeStampToDate(el.telegram.last_time) : "",
        }
        result.push(results)
      }

      if (el.whatsapp && !el.whatsapp.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "whatsapp",
          status: el.whatsapp.status ? el.whatsapp.status : "",
        }
        result.push(results)
      }

      if (el.callapp && !el.callapp.map) {

        const results = {
          phone: el.query ? el.query : "",
          source: "callapp",
          name: el.callapp.name ? el.callapp.name : "",
          facebook: el.callapp.facebookID ? el.callapp.facebookID.id : "",
          instagram: el.callapp.instagramID ? el.callapp.instagramID.id : "",
          emails: getCallappEmails(el.callapp),
          googlePlus: el.callapp.googlePlusID ? el.callapp.googlePlusID.id : "",
        }
        result.push(results)
      }

      if (el.getcontact && !el.getcontact.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "getcontact",
          name: el.getcontact.name ? el.getcontact.name : "",
        }
        result.push(results)
      }

      if (el.drupe && !el.drupe.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "drupe",
          name: el.drupe.name ? el.drupe.name : "",
        }
        result.push(results)
      }

      if (el.eyecon && !el.eyecon.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "eyecon",
          name: el.eyecon.name ? el.eyecon.name : "",
          facebook: el.eyecon.facebook_id ? el.eyecon.facebook_id : "",
        }
        result.push(results)
      }

      if (el.hiya && !el.hiya.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "hiya",
          name: el.hiya.displayName ? el.hiya.displayName : "",
        }
        result.push(results)
      }

      if (el.tc && el.tc.contact && !el.tc.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "tc",
          name: el.tc.contact.name ? el.tc.contact.name : "",
        }
        result.push(results)
      }

      if (el.imo && !el.imo.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "imo",
          nickname: el.imo.nickname ? el.imo.nickname : "",
        }
        result.push(results)
      }

      if (el.viber && !el.viber.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "viber",
          name: el.viber.name ? el.viber.name : "",
          last_seen: el.viber.online ? timeStampToDate(el.viber.online) : "",
        }
        result.push(results)
      }

      if (el.tamtam && !el.tamtam.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "tamtam",
          name: tamTamNames(el.tamtam),
        }
        result.push(results)
      }

      if (el.skype && !el.skype.map) {
        const results = {
          phone: el.query ? el.query : "",
          source: "skype",
          name: el.skype.name ? el.skype.name : "",
        }
        result.push(results)
      }
    })
  }

  if (lsiWatsapp !== []) {
    const lsiWhatsappResults = lsiWatsapp.flat()
    lsiWhatsappResults.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "whatsapp",
        status: el?.value?.status || "",
        description: el?.value?.business?.profile?.description || "",
        emails: el?.value?.business?.profile?.email || "",
        instagram: el?.value?.business?.profile?.linked_accounts?.ig_professional?.ig_handle || "",
      }
      result.push(results)
    })
  }

  if (whatsapp !== []) {
    whatsapp.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "whatsapp",
        status: el.value.status || "",
      }
      result.push(results)
    })
  }


  if (telegram !== []) {
    telegram.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "telegram",
        name: el.value.name || "",
        nickname: el.value.nickname || "",
        last_seen: el.value.last_seen || "",
      }
      result.push(results)
    })
  }

  if (callapp !== []) {
    callapp.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "callapp",
        name: el.value.name || "",
        facebook: el.value.facebookID && el.value.facebookID.id ? el.value.facebookID.id : "",
        instagram: el.value.instagramID && el.value.instagramID.id ? el.value.instagramID.id : "",
        twitter: el.value.twitterScreenName && el.value.twitterScreenName.id ? el.value.twitterScreenName.id : "",
        emails: getCallappEmails(el.value),
        googlePlus: el.value.googlePlusID && el.value.googlePlusID.id ? el.value.googlePlusID.id : "",
      }
      result.push(results)
    })
  }

  if (drupe !== []) {
    drupe.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "drupe",
        name: el.value.name ? el.value.name : "",
      }
      result.push(results)
    })
  }

  if (getcontact !== []) {
    getcontact.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "getcontact",
        name: el.value.displayName ? el.value.displayName : "",
      }
      result.push(results)
    })
  }

  if (tc !== []) {
    tc.forEach((el: any) => {
      const results = {
        phone: el.key || "",
        source: "tc",
        name: el.value && el.value.name ? el.value.name : "",
      }
      result.push(results)
    })
  }

  result.sort((a: any, b: any) => (a.phone > b.phone) ? 1 : ((b.phone > a.phone) ? -1 : 0))

  const xlsx: any = []

  let phone = (result[0] && result[0].phone) ? result[0].phone : ""
  let sources: string[] = []
  let names: string[] = []
  let nicknames: string[] = []
  let lastSeens: string[] = []
  let status: string[] = []
  let facebook: string[] = []
  let instagram: string[] = []
  let twitter: string[] = []
  let emails: string[] = []
  let description: string[] = []

  for (let i = 0; i < result.length; i++) {
    if (phone === result[i].phone) {

      phone = result[i].phone
      if (result[i].name && result[i].name !== '')
        names.push(result[i].name)

      if (result[i].nickname && result[i].nickname !== '')
        nicknames.push(result[i].nickname)

      if (result[i].last_seen && result[i].last_seen !== '')
        lastSeens.push(result[i].last_seen)

      if (result[i].status && result[i].status !== '')
        status.push(result[i].status)

      if (result[i].facebook && result[i].facebook !== '')
        facebook.push(result[i].facebook)

      if (result[i].instagram && result[i].instagram !== '')
        instagram.push(result[i].instagram)

      if (result[i].twitter && result[i].twitter !== '')
        twitter.push(result[i].twitter)

      if (result[i].description && result[i].description !== '')
      description.push(result[i].description)

      if (result[i].emails && result[i].emails !== '')
        emails.push(result[i].emails)

      if (result[i].name !== '' ||
        result[i].nickname !== '' ||
        result[i].last_seen !== '' ||
        result[i].status !== '' ||
        result[i].facebook !== '' ||
        result[i].instagram !== '' ||
        result[i].twitter !== '' ||
        result[i].emails !== '')
        sources.push(result[i].source)

    } else {
      xlsx.push(
        {
          phone: phone,
          sources: `[${sources.toString().toUpperCase()}]`,
          names: names.toString(),
          emails: emails.toString(),
          nickname: nicknames.toString(),
          facebook: facebook.toString(),
          instagram: instagram.toString(),
          twitter: twitter.toString(),
          description: description.toString(),
          lastSeens: lastSeens.toString(),
          status: status.toString(),
        })
      sources = []
      names = []
      nicknames = []
      lastSeens = []
      status = []
      facebook = []
      instagram = []
      twitter = []
      emails = []
      description = []

      phone = result[i].phone

      if (result[i].name && result[i].name !== '')
        names.push(result[i].name)

      if (result[i].nickname && result[i].nickname !== '')
        nicknames.push(result[i].nickname)

      if (result[i].last_seen && result[i].last_seen !== '')
        lastSeens.push(result[i].last_seen)

      if (result[i].status && result[i].status !== '')
        status.push(result[i].status)

      if (result[i].facebook && result[i].facebook !== '')
        facebook.push(result[i].facebook)

      if (result[i].instagram && result[i].instagram !== '')
        instagram.push(result[i].instagram)

      if (result[i].twitter && result[i].twitter !== '')
        twitter.push(result[i].twitter)

      if (result[i].description && result[i].description !== '')
      description.push(result[i].description)

      if (result[i].emails && result[i].emails !== '')
        emails.push(result[i].emails)

      if (result[i].name !== '' ||
        result[i].nickname !== '' ||
        result[i].last_seen !== '' ||
        result[i].status !== '' ||
        result[i].facebook !== '' ||
        result[i].instagram !== '' ||
        result[i].twitter !== '' ||
        result[i].description !== '' ||
        result[i].emails !== '')
        sources.push(result[i].source)
    }
    
    if (i === result.length - 1) {
      xlsx.push(
        {
          phone: phone,
          sources: `[${sources.toString().toUpperCase()}]`,
          names: names.toString(),
          emails: emails.toString(),
          nickname: nicknames.toString(),
          facebook: facebook.toString(),
          instagram: instagram.toString(),
          twitter: twitter.toString(),
          description: description.toString(),
          lastSeens: lastSeens.toString(),
          status: status.toString(),
        })
    }
  }
  return xlsx
}